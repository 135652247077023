<template>
  <el-form
    ref="form"
    style="flex: 1; margin-top: 20px; margin-left: 40px; margin-bottom: 80px"
    :model="formData"
    label-position="left"
    label-width="100px"
  >
    <el-form-item label="邮箱类型" required>
      <el-select
        v-model="formData.emailType"
        size="small"
        style="width: 270px"
        placeholder="请选择邮箱类型"
        @change="emailTypeChange"
        clearable
      >
        <el-option label="腾讯企业邮箱" value="1"></el-option>
        <el-option label="阿里企业邮箱" value="2"></el-option>
        <el-option label="网易企业邮箱" value="4"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="邮箱版本" required>
      <el-select
        v-model="formData.emailVersion"
        size="small"
        style="width: 270px"
        placeholder="请选择邮箱版本"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="用户数" required>
      <el-input
        style="width: 270px"
        v-model.number="formData.number"
        size="small"
        placeholder="请输入用户数"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>
    <el-form-item label="购买年限" required>
      <el-input
        style="width: 270px"
        v-model="formData.term"
        size="small"
        placeholder="请输入购买年限"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>
    <el-form-item label="折扣" required>
      <el-input
        style="width: 270px"
        v-model="formData.discount"
        size="small"
        placeholder="请输入折扣（1-100）"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>

    <el-form-item label="扩容数据：">
    </el-form-item>
    <el-form-item label="开始日期">
      <el-date-picker
        style="width: 270px"
        value-format="yyyy-MM-dd"
        size="small"
        v-model="formData.startTime"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="到期日期">
      <el-date-picker
        style="width: 270px"
        value-format="yyyy-MM-dd"
        size="small"
        v-model="formData.expireTime"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="扩容用户数">
      <el-input
        style="width: 270px"
        v-model.number="formData.dilatationNumber"
        size="small"
        placeholder="请输入用户数"
        clearable
        oninput="value=value.replace(/[^\d]/g,'')"
      ></el-input>
    </el-form-item>

    <el-button
      round
      type="primary"
      style="
        background: #2370eb;
        border-color: #2370eb;
        color: #fff;
        width: 270px;
        margin-left: 50px;
      "
      size="mini"
      @click="onCalculation"
      >计算</el-button
    >
    <p style="height: 50px"></p>
    <el-form-item
      label="结算价："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">
        {{ formData.formatAmount }}
      </p>
    </el-form-item>
    <el-form-item
      label="扩容结算价："
      v-mode="showEl"
      v-if="showEl == true"
      type="hidden"
    >
      <p style="font-size: 14px">{{ formData.dilatationAmount }}</p>
    </el-form-item>
  </el-form>
</template>
<script>
import { calculation } from "@/api/cs/tool.js";
export default {
  data() {
    return {
      formData: {
        emailType: "1",
        emailVersion: 1,
        number: "",
        dilatationNumber: "",
        term: "",
        expireTime: "",
        startTime: "",
        formatAmount: "",
        dilatationAmount: "",
        discount:"100",
      },
      showEl: false,
      options: [
        {
          label: "老版",
          value: 1,
        },
        {
          label: "新版",
          value: 2,
        },
      ],
    };
  },
  methods: {
    onCalculation() {
      if (!this.formData.number) {
        this.$message.error("请输入用户数");
        return;
      }
      if (this.formData.number < 5) {
        this.$message.error("用户数不能少于5");
        return;
      }
      if (!this.formData.term) {
        this.$message.error("请输入年限");
        return;
      }
      if (!this.formData.discount) {
        this.$message.error("请输入折扣");
        return;
      }
      if (this.formData.discount < 1 || this.formData.discount > 100) {
        this.$message.error("折扣范围区间1-100");
        return;
      }
      this.showEl = true;
      let data = {
        param: {
            productCategory: this.formData.emailType,
            version: this.formData.emailVersion,
            number: this.formData.number,
            term: this.formData.term,
            expireTime: this.formData.expireTime,
            startTime: this.formData.startTime,
            dilatationNumber: this.formData.dilatationNumber,
            discount:this.formData.discount
        },
      };

      var that = this;
      if (this.domain != "") {
        calculation(data).then((res) => {
          console.log(res);
          //this.data = res.data;
          that.formData.formatAmount = res.data.formatAmount;
          that.formData.dilatationAmount = res.data.dilatationAmount;
        });
      }
      
    },
    emailTypeChange() {
      if (this.formData.emailType == 1) {
        this.options = [];
        let obj1 = { label: "老版", value: 1 };
        let obj2 = { label: "新版", value: 2 };
        this.options[0] = obj1;
        this.options[1] = obj2;
        this.formData.emailVersion = 1;
      } else if (this.formData.emailType == 2) {
        this.options = [];
        let obj1 = { label: "标准版", value: 1 };
        let obj2 = { label: "尊享版", value: 2 };
        let obj3 = { label: "集团版", value: 3 };
        this.options[0] = obj1;
        this.options[1] = obj2;
        this.options[2] = obj3;
        this.formData.emailVersion = 1;
      } else {
        this.options = [];
        let obj1 = { label: "Hmail旗舰版", value: 1 };
        let obj2 = { label: "Hmail5G", value: 2 };
        let obj3 = { label: "尊享版", value: 3 };
        this.options[0] = obj1;
        this.options[1] = obj2;
        this.options[2] = obj3;
        this.formData.emailVersion = 1;
      }
    },
  },
};
</script>
